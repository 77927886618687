import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGit,
} from "react-icons/di";
import { TbRotateDot } from "react-icons/tb";
import { FaBitbucket , FaAws } from "react-icons/fa";
import {
  SiNextdotjs,
  SiTypescript,
  SiPostgresql,
  SiMicrosoftazure,
  SiTailwindcss 
} from "react-icons/si";


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>

      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p className="" style={{fontSize: '16px' }}>Javascript</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <p className="" style={{fontSize: '16px' }}>Node Js</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <p className="" style={{fontSize: '16px' }}>React Js</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <p className="" style={{fontSize: '16px' }}>Mongo Db</p>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <SiTailwindcss />
        <p className="" style={{fontSize: '16px' }}>Tailwind CSS</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
        <p className="" style={{fontSize: '16px' }}>Next Js</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p className="" style={{fontSize: '16px' }}>Git</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        < FaBitbucket />
        <p className="" style={{fontSize: '16px' }}>Bit Bucket</p>
      </Col>
      
  
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftazure />
        <p className="" style={{fontSize: '16px' }}>Azure</p>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <FaAws />
        <p className="" style={{fontSize: '16px' }}>AWS</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        < TbRotateDot />
        <p className="" style={{fontSize: '16px' }}>Agile</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <p className="" style={{fontSize: '16px' }}>PostgreSql</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        < SiTypescript />
        <p className="" style={{fontSize: '16px' }}>Typescript</p>
      </Col>
    
      <Col xs={4} md={2} className="tech-icons">
        <CgCPlusPlus />
        <p className="" style={{fontSize: '16px' }}>C++</p>
      </Col>

    </Row>
  );
}

export default Techstack;
