import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import kidsparadise from "../../Assets/Projects/kidsparadise.png";
import netflix from "../../Assets/Projects/netflix clone.png";
// import olx from "../../Assets/Projects/olx.png";
// import userManagement from "../../Assets/Projects/user management.png";

import socialchat from "../../Assets/Projects/socialchat.png";
import nextApp from "../../Assets/Projects/nextApp.png";
import todo from "../../Assets/Projects/todo app.png";
import youtubeclone from "../../Assets/Projects/youtubeclone.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've crafted recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>


        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={socialchat}
              isBlog={false}
              title="Chat App (still progressing)"
              description="Currently developing a social media chat application using the MERN stack (MongoDB, Express.js, React.js, Node.js). The app incorporates Redux for efficient state management, Tailwind CSS for styling, and Socket.io for real-time communication. It aims to provide features such as real-time messaging, multimedia sharing, and user interactions to create an engaging and dynamic user experience."
              ghLink="https://github.com/anas-ma007/SocialChat"
            // demoLink="https://github.com/anas-ma007/SocialChat" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kidsparadise}
              isBlog={false}
              title="Kidsparadise-Ecommerce"
              description="KidsParadise is an ecommerce web application that utilizes Node.js and Express.js for the backend, with MongoDB for data storage. The frontend is built using EJS for templating and AJAX for seamless, real-time interactions. This setup ensures a dynamic and responsive user experience, allowing for efficient product management and smooth customer transactions."
              ghLink="https://github.com/anas-ma007/KidsParadise"
              demoLink="https://www.linkedin.com/posts/anas-ma_javascript-frontend-aws-activity-7132380526937604096-ATyf?utm_source=share&utm_medium=member_desktop"
            />
          </Col>
          

         
          

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={youtubeclone}
              isBlog={false}
              title="Youtube Clone"
             
              description="Developed a YouTube clone app using React and Tailwind CSS, leveraging the Google API for data. Utilized Hooks and Redux Toolkit for efficient state management. Enhanced the user interface with shimmer effects and integrated live suggestions using a suggestion API with debouncing to optimize search performanceDeveloping a YouTube clone utilizing React for the frontend framework, Redux for state management, and Tailwind CSS for styling"
              ghLink="https://github.com/anas-ma007/youtube-clone"
              demoLink="hhttps://www.linkedin.com/posts/anas-ma_react-tailwindcss-reduxtoolkit-activity-7132608582629433344-QjGv?utm_source=share&utm_medium=member_desktop"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={netflix}
              isBlog={false}
              title="Netflix Clone"
              description="Utilized React.js, React hooks, such as useState and useEffect, to build a single-page application (SPA) architecture."
              ghLink="https://github.com/anas-ma007/netflix-react"
              demoLink="https://netflix-react-flax.vercel.app/"

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={todo}
              isBlog={false}
              title="A Todo App"
              description="A todo list web application using React Js, Css Grid and Flex box enabling users to create, edit and delete tasks. Utilized local storage to persist data."
              ghLink="https://github.com/anas-ma007/TodoApp-React"
              demoLink="https://todo-app-react-chi-three.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nextApp}
              isBlog={false}
              title="Next Js App"
              description="Utilizing Next.js for server-side rendering and data fetching, efficiently rendering data with its SSR capabilities, and styled with CSS for UI/UX enhancements"
              ghLink="https://github.com/anas-ma007/Appscrip-task-MuhammedAnas"
              demoLink="https://appscrip-task-muhammed-anas.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
