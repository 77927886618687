import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi, I am <span className="purple">Muhammed Anas </span>
            from <span className="purple"> Kerala, India.</span>
            <br />
            <br />


            Full-Stack Developer Specialized in <span className="purple">MERN Stack  </span> Solutions
            <br />
            Armed with a B.Tech degree with a strong proficiency in crafting comprehensive web applications using the MERN stack— <span className="purple"> MongoDB, Express, React, and Node.js.</span> I thrive in collaborative environments, working seamlessly with cross-functional teams to create outstanding user experiences and deliver robust, scalable solutions.
            <br />
            {/* Apart from coding, some other activities that I love to do! */}
          </p>
          {/* <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
           
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul> */}

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Anas</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
